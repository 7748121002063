export const API_ENDPOINT = 'https://testapi.curoflow.dk';
// export const API_ENDPOINT = 'http://localhost:4005';

export const GOOGLE_API_KEY = 'AIzaSyDIkpF79Bh5QTNDHaqa3TfCspe_UiJqGqI';

export const GOOGLE_MAPS_API_KEY = 'AIzaSyBRBXF6CgXRhPRmmq74OWgOFqpKJHXz-0M';

export const SENTRY_DSN = "https://bc4a8c7249254b1198232bbdf27b6a68@o4504604682944512.ingest.sentry.io/4504604702736385"

export const DOMAINS = [
    {
        domen: 'https://dev.curoflow.se',
        api: 'https://devapi.curoflow.se',
        name: 'SE - DEV',
        country: 'sweden'
    },
    {
        domen: 'https://test.curoflow.se',
        api: 'https://testapi.curoflow.se',
        name: 'SE - TEST',
        country: 'sweden'
    },
    {
        domen: 'https://tryme.curoflow.se',
        api: 'https://tryme-api.curoflow.se',
        name: 'SE - TRYME',
        country: 'sweden'
    },
    {
        domen: 'https://dashboard.curoflow.se',
        api: 'https://dashboardapi.curoflow.se',
        name: 'SE - DASHBOARD',
        country: 'sweden'
    },
    {
        domen: 'https://sandbox.curoflow.se',
        api: 'https://sandboxapi.curoflow.se',
        name: 'SE - SANDBOX',
        country: 'sweden'
    },
    {
        domen: 'https://test.curoflow.dk',
        api: 'https://testapi.curoflow.dk',
        name: 'DK - TEST',
        country: 'denmark'
    },
    {
        domen: 'https://tryme.curoflow.dk',
        api: 'https://tryme-api.curoflow.dk',
        name: 'DK - TRYME',
        country: 'denmark'
    },
    {
        domen: 'https://dashboard.curoflow.dk',
        api: 'https://dashboardproxy.curoflow.dk',
        name: 'DK - DASHBOARD',
        country: 'denmark'
    },
    {
        domen: 'https://test.curoflow.uk',
        api: 'https://testapi.curoflow.uk',
        name: 'UK - TEST',
        country: 'uk'
    },
    {
        domen: 'https://tryme.curoflow.uk',
        api: 'https://tryme-api.curoflow.uk',
        name: 'UK - TRYME',
        country: 'uk'
    },
    {
        domen: 'https://dashboard.curoflow.uk',
        api: 'https://dashboardproxy.curoflow.uk',
        name: 'UK - DASHBOARD',
        country: 'uk'
    },
    {
        domen: 'https://test.curoflow.ch',
        api: 'https://testapi.curoflow.ch',
        name: 'CH - TEST',
        country: 'ch'
    },
    {
        domen: 'https://tryme.curoflow.ch',
        api: 'https://tryme-api.curoflow.ch',
        name: 'CH - TRYME',
        country: 'ch'
    },
    {
        domen: 'https://dashboard.curoflow.ch',
        api: 'https://dashboardproxy.curoflow.ch',
        name: 'CH - DASHBOARD',
        country: 'ch'
    },
    {
        domen: 'https://test.curoflow.gr',
        api: 'https://testapi.curoflow.gr',
        name: 'GR - TEST',
        country: 'gr'
    },
    {
        domen: 'https://tryme.curoflow.gr',
        api: 'https://tryme-api.curoflow.gr',
        name: 'GR - TRYME',
        country: 'gr'
    },
    {
        domen: 'https://dashboard.curoflow.gr',
        api: 'https://dashboardproxy.curoflow.gr',
        name: 'GR - DASHBOARD',
        country: 'gr'
    },
    {
        domen: 'https://test.es.curoflow.com',
        api: 'https://test-api.es.curoflow.com',
        name: 'ES - TEST',
        country: 'es'
    },
    {
        domen: 'https://tryme.es.curoflow.com',
        api: 'https://tryme-api.es.curoflow.com',
        name: 'ES - TRYME',
        country: 'es'
    },
    {
        domen: 'https://dashboard.es.curoflow.com',
        api: 'https://dashboardproxy.es.curoflow.com',
        name: 'ES - DASHBOARD',
        country: 'es'
    },
    {
        domen: 'https://test.curoflow.ae',
        api: 'https://testapi.curoflow.ae',
        name: 'AE - TEST',
        country: 'ae'
    },
    {
        domen: 'https://tryme.curoflow.ae',
        api: 'https://trymeapi.curoflow.ae',
        name: 'AE - TRYME',
        country: 'ae'
    },
    {
        domen: 'https://dashboard.curoflow.ae',
        api: 'https://dashboardproxy.curoflow.ae',
        name: 'AE - DASHBOARD',
        country: 'ae'
    }
]
export const CHILD_AGE_LIMIT = {
    denmark: 15,
    sweden: 13,
    uk: 18,
    es: 18,
    ch: 18,
    gr: 18,
    ae: 18,
}
